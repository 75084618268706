/**
 * Layout
 */

@use '~Styles/base/vars' as v;
@use '~Styles/vendors/include-media' as *;
@use '~Styles/base/placeholders';

body {
  padding: 1.5rem;
  width: 100vw;
  height: 100vh;
  background-color: v.$body-bg-color;

  @include media('>=sm') {
    padding: 3rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: v.$layout-bg-color;
  border: v.$border-width solid v.$border-color;
  border-radius: v.$border-radius;
  overflow: hidden;
}

.header,
.footer {
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.main {
  flex-grow: 1;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 0;

  & > .section {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: calc(var(--main-node-height) + v.$border-width);

    &:not(:last-child) {
      border-bottom: v.$border-width solid v.$border-color;
    }

    &_wide {
      @extend %block-padding;
    }

    &_2col {
      @include media('>=#{v.$mobile-menu-breakpoint}') {
        flex-direction: row;
      }
    }

    .h2 {
      padding: 2.1rem 1rem 2.9rem;
    }
  }
}

.header {
  border-bottom: v.$border-width solid v.$border-color;
}

.footer {
  border-top: v.$border-width solid v.$border-color;
}

.sidebar {
  width: 100%;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    max-width: v.$sidebar-width;
    border-right: v.$border-width solid v.$border-color;
  }
}

.column {
  width: 100%;
  overflow-x: scroll;
}
