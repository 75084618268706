/**
 * Typography
 */

@use '~Styles/base/vars' as v;
@use '~Styles/base/placeholders';

// @font-face {
//   font-family: 'Fira Code';
//   src: url('~Fonts/FiraCode-Regular.woff2') format('woff2'),
//     url('~Fonts/FiraCode-Regular.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Fira Code';
//   src: url('~Fonts/FiraCode-Medium.woff2') format('woff2'),
//     url('~Fonts/FiraCode-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Fira Code';
//   src: url('~Fonts/FiraCode-SemiBold.woff2') format('woff2'),
//     url('~Fonts/FiraCode-SemiBold.woff') format('woff');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Fira Code';
//   src: url('~Fonts/FiraCode-Bold.woff2') format('woff2'),
//     url('~Fonts/FiraCode-Bold.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Fira Code VF';
  src: url('~Fonts/FiraCode-VF.woff2') format('woff2-variations'),
    url('~Fonts/FiraCode-VF.woff') format('woff-variations');
  font-weight: 300 700;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: v.$basic-font-size;
}

body {
  font-family: v.$body-font;
  font-weight: v.$body-font-weight;
  font-size: v.$paragraph;
  line-height: v.$line-height;
  color: v.$text-color;

  @supports (font-variation-settings: normal) {
    font-family: v.$font-family-vf;
    font-weight: v.$body-font-vf-weight;
  }
}

h1,
h2,
h3 {
  font-family: v.$header-font;
  font-weight: v.$header-font-weight;

  @supports (font-variation-settings: normal) {
    font-family: v.$font-family-vf;
    font-weight: v.$header-font-vf-weight;
  }
}

h1 {
  font-size: 6.2rem;
  line-height: 8.1rem;
}

h2 {
  font-size: 1.4rem;
  line-height: 140%;
  text-transform: lowercase;

  &:before {
    content: '_';
  }
}

html,
button,
input,
select,
textarea {
  color: v.$text-color;
}

a {
  color: v.$text-color;
  text-decoration: none;

  @extend %transition;

  &:visited,
  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    outline: 0;
  }

  &:after {
    @extend %transition;
  }
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}
